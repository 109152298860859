#preview-container {
    width: 90vw;
    background-color: snow;
    color: black;
    border-radius: 3px;
}

#name-container {
    padding: 20px 18vw;
}
 
#name-container > h2 {
    padding-right: 30px;
}

#content-holder {
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

#image-holder {
    width: 500px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 30px 0px;
}

#image-holder > img {
    max-width: 450px;
    max-height: 300px;
}

#text-holder {
    display: flex;
    flex-wrap: wrap;
}

.text-area {
    padding: 0px 30px;
}

ul, li { 
    margin-left: 0; 
    padding-left: 0; 
}

hr {
    margin: 0; 
}

@media (max-width: 1000px) {

    #name-container {
        padding: 20px 30px 5px 30px;
    }

    #name-container > h2 {
        padding-right: 0px;
    }

    #content-holder {
        justify-content: flex-start;
        padding: 0px 20px 20px 20px;
    }

    #image-holder {
        width: 300px;
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 10px 0px;
    }

    #image-holder > img {
        max-width: 280px;
        max-height: 200px;
    }

    .text-area {
        padding: 0px 20px;
    }  
}