
.nutty-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
    background-color: #edb72f;
    box-shadow: inset 0px 0px 30px #fff8e6;
} 

@media (max-width: 1000px) {
    .nutty-content-container {
        padding: 10px 0px;
    }
} 