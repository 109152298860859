#displayed-artwork-holder {
    background-color:#b5b5b5;
    box-shadow: inset 0 0 10px black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75vh;
    padding: 10px 0px;
}

#artwork-and-caption-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
    width: 50vw; 
}

#caption {
    padding-top: 20px;
}

#artwork {
    max-width: 100%;
    max-height: 90%;
    box-shadow: 0px 0px 7px rgba(0,0,0,0.6);
}

.arrow-area {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    margin: 0px 20px;
}
.arrow-area:hover {
    background-color: rgba(0,0,0,0.2);
}

@media (max-width: 500px) {
    #displayed-artwork-holder {
        height: 50vh;
        padding: 0px 0px;
    }
    #artwork-and-caption-holder {  
        height: 45vh;
        width: 90vw; 
    }
    .arrow-area {
        width: 30px;
        height: 30px;
        border-radius: 5px;
        margin: 0px 5px;
    }
    #artwork {
        max-height: 70%;
    }

}