.study-content-container {
    background-color: snow;
    box-shadow: inset 0 0 20px black;
    padding: 20px 50px;
}

@media (max-width: 1000px) {
    .study-content-container {
        padding: 10px;
    } 
}

/*****************Case Study Title*****************/

.study-label-holder {
    font-size: 1.2rem;
    padding-left: 20px;
    background-color: snow;
}
 
.study-label {
    margin: 0; 
}
 
.study-name {
    margin: 0;
}

.study-name-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    color: snow;
    background-color: black;
    padding: 30px 10px;
}

@media (max-width: 1000px) {
    .study-label-holder {
        padding-left: 10px;
    }
    .study-name-holder {
        padding: 15px 10px;
    }
}

/*****************Hero*****************/

.study-hero {
    width: 100%;
    margin-bottom: 20px;
}

@media (max-width: 1000px) {
    .study-hero {
        margin-bottom: 10px;
    }
}

/*****************Prototype Link Button*****************/
 
#link-btn-area {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    padding-right: 4vw;
}
.link-btn {
    margin: 0px 20px;
    padding: 20px;
    font-weight: 600;
    font-size: 20px;
    background-color: #5dd9ab;
    background-image: linear-gradient(10deg, #5dd9ab 0%, #daf2b1 100%);
    border-radius: 10px;
    box-shadow: 3px 3px 8px gray;
} 
@media (max-width: 1000px) {
    #link-btn-area {
        justify-content: center;
        align-items: center;
        padding-right: 0vw;
        margin-bottom: 20px;
    }
    .link-btn {
        margin: 10px 20px;
        padding: 10px;
        font-size: 16px;
        background-color: pink;
        border-radius: 10px;
        box-shadow: 3px 3px 8px gray;
    } 
}

/*****************SubTitle*****************/

.subtitle-container {
    border-bottom: solid;
    margin-bottom: 30px;
    font-size: 1.2rem;
}

.subtitle {
    margin: 0 0 0 30px;
}

@media (max-width: 1000px) {
    .subtitle-container {
        margin-bottom: 20px;
    }
    .subtitle {
        margin: 0 0 0 10px;
    }
}

/*****************Case Study Image Area*****************/

.img-label {
    margin: 0 0 0 30px;
    font-size: 1.5rem;
}

.imgs-holder {
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start; 
    padding: 20px;
    background-color: #b7c4c3;
    box-shadow: inset 0 0 10px gray;
    margin-bottom: 30px;
}

.imgs-holder > img {
    box-shadow: 1px 1px 5px gray;
}

.desktop {
    width: 350px;
    margin: 5px;
}
.tablet {
    width: 125px;
    margin: 5px;
}
.mobile {
    width: 60px;
    margin: 5px;
}
.prototype {
    width: 500px;
    margin: 5px;
}
.asset {
    width: 120px;
    margin: 5px;
}
.vertical-paper {
    width: 175px;
    margin: 5px;
}

@media (max-width: 600px) {
    .img-label {
        margin: 0 0 0 10px;
        font-size: 1.2rem;
    }
    .desktop {
        width: 90%;
    }
    .tablet {
        width: 30%;
    }
    .mobile {
        width: 20%;
    }
    .prototype {
        width: 95%;
    }
    .asset {
        width: 50px;
    }
    .vertical-paper {
        width: 40%;
    }
}
/*****************Line*****************/

.line-area {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
}

.line-topic-holder {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.line-topic {
    font-size: 1.5rem;
    margin: 0;
}

.line-content-holder {
    width: 80%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
}

.line-content {
    font-size: 1.3rem;
    margin: 0;
}

@media (max-width: 1000px) {
    .line-area {
        flex-direction: column;
    }
    .line-topic-holder {
        justify-content: flex-start;
        padding: 0px 0px 10px 15px;
        width: 100%;
    }
    .line-topic {
        font-size: 1.5rem;
    }
    .line-content-holder {
        width: 90%;
        padding-left: 20px;
        padding-right: 20px;
    }
    .line-content {
        font-size: 1.2rem;
    }

}

/*****************List*****************/

.list-area {
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 20px;
}

.list-topic-holder {
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.list-topic {
    margin: 0;
    font-size: 1.5rem;
}

.list-content-holder {
    width: 80%;
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

.list-item {
    font-size: 1.3rem;
}

@media (max-width: 1000px) {
    .list-area {
        flex-direction: column;
    }
    .list-topic-holder {
        justify-content: flex-start;
        padding: 0px 0px 10px 15px;
        width: 90%;
    }
    .list-topic {
        font-size: 1.5rem;
    }
    .list-content-holder {
        padding-left: 20px;
    }
    .list-item {
        font-size: 1.2rem;
        margin-bottom: 10px;
    }
}

