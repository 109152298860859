.project-preview-container {
    height: 220px;
    border-bottom: 2px solid black;
    display: flex;
    align-items: center;
    padding: 30px;
    /* box-shadow: inset 0 0 20px #000000; */
    background-color: snow;
}

.pic-container {
    margin: 50px;
    display: flex;
    justify-content: center; 
    align-items: center;
}

.pic-container > img {
    width: 250px;
}
 
.right {
    justify-content: flex-end;
}

.left {
    flex-direction: row-reverse;
    justify-content: flex-end;
} 

@media (max-width: 600px) {
    .project-preview-container {
        height: 200px;
        padding: 10px;
    }
    
    .pic-container {
        margin: 10px;
    }
    
    .pic-container > img {
        width: 160px;
    }
}