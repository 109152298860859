#nav-container {
    display: flex;
    justify-content: right;
    align-items: center;
    background-color: snow;
    color: black;
    font-size: 1.2rem;
}

.nav-child {
    margin: 20px 40px; 
}

#logo {
    flex-grow: 1;
    width: 80px;
    padding-left: 5vw;
    padding-top: 10px;
} 

#flyout-menu-icon {
    display: none;
}

#flyout-menu {
    display: none;
}

@media (max-width: 1000px) {
    #nav-container {
        display: block;
        height: 48px;
    }

    .nav-child {
        display: none;
    }

    #logo {
        width: 48px;
        padding-top: 5px;
        padding-right: 10px;
        float: right;
    }
 
    #flyout-menu-icon {
        display: inline;
        padding-top: 5px;
        padding-left: 10px;
        cursor: pointer;
    }
    #open-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30vw;
        height: 100vh;
        position: fixed;
        top: 0;
        background-color: snow;
        border-right: 2px solid black;
        animation-duration: 200ms;
        animation-name: slidein;
    }

    .flyout-button {
        font-size: 1.2rem;
        font-weight: 600;
        padding: 20px;
    }

    #close-menu-icon-area {
    width: 100%;
    display: flex;
    justify-content: flex-start; 
    cursor: pointer;
    padding-left: 20px;
    padding-top: 10px;
    }

    @keyframes slidein {
        from { 
          transform: translateX(-100%);
        }
      
        to {
          transform: translateX(0%);
        }
      }

    #close-menu {
        display: flex;
        flex-direction: column;
        width: 50vw;
        height: 100vh;
        position: fixed;
        top: 0;
        transform: translateX(-100%);
        background-color: snow;
        border-right: 2px solid black;
        animation-duration: 200ms;
        animation-name: slideout;
    }

    @keyframes slideout {
        from {
            transform: translateX(0%);
        }

        to {
            transform: translateX(-100%);
        }
    }
}

@media (max-width: 500px) {
    #open-menu {
        width: 50vw;
    }
} 