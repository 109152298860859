#resume-page-body {
    display: flex;
    justify-content: center;
    background-color: #9c1e48;
    box-shadow: inset 0px 0px 30px #fad6be;
}

#resume-pic {
    width: 60%;
    height: auto;
    margin: 30px 5px;

} 

@media (max-width: 1000px) { 
    #resume-pic {
        width: 95%;
        height: auto;
    }
}

/* #resumeFrame {
    width: 60%;
    height: 140vh;
    margin: 30px 5px;

} 

@media (max-width: 1000px) { 
    #resumeFrame {
        width: 100%;
        height: 80vh;
    }
} */


