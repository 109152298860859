


.aa{fill:url(#t);}.ab{fill:url(#r);}.ac{fill:url(#y);}.ad{fill:url(#l);}.ae{fill:url(#c);}.af{fill:url(#u);}.ag{fill:url(#v);}.ah{fill:url(#a`);}.ai{fill:url(#m);}.aj{fill:url(#f);}.ak{fill:url(#p);}.al{fill:url(#g);}.am{fill:url(#b);}.an{fill:url(#i);}.ao{fill:url(#h);}.ap{fill:url(#d);}.aq{fill:url(#e);}.ar{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:5px;}.as{fill:url(#x);}.at{fill:url(#s);}.au{fill:url(#n);}.av{fill:url(#w);}.aw{fill:url(#j);}.ax{fill:url(#o);}.ay{fill:url(#q);}.b{fill:url(#k);}

 .fun:hover {
    fill-opacity: 0.8
 }




 .cls-1{fill:url(#linear-gradient-2);}.cls-2{fill:url(#radial-gradient-7);}.cls-3{fill:url(#radial-gradient-10);}.cls-4{fill:url(#radial-gradient-16);}.cls-5{fill:url(#radial-gradient-8);}.cls-6{fill:url(#linear-gradient-6);}.cls-7{fill:url(#radial-gradient-5);}.cls-8{fill:url(#radial-gradient-4);}.cls-9{fill:url(#radial-gradient-6);}.cls-10{fill:url(#radial-gradient-12);}.cls-11{fill:url(#radial-gradient);}.cls-12{fill:url(#radial-gradient-13);}.cls-13{fill:url(#linear-gradient-5);}.cls-14{fill:url(#radial-gradient-15);}.cls-15{fill:url(#radial-gradient-2);}.cls-16{fill:none;stroke:#000;stroke-miterlimit:10;stroke-width:5px;}.cls-17{fill:url(#radial-gradient-3);}.cls-18{fill:url(#linear-gradient-7);}.cls-19{fill:url(#radial-gradient-17);}.cls-20{fill:url(#radial-gradient-14);}.cls-21{fill:url(#linear-gradient-3);}.cls-22{fill:url(#linear-gradient);}.cls-23{fill:url(#linear-gradient-4);}.cls-24{fill:url(#radial-gradient-11);}.cls-25{fill:url(#radial-gradient-9);}.cls-26{fill:url(#linear-gradient-8);}