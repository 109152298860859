#intro-container {
    width: 50vw;
    background-color: snow;
    padding: 10px 50px;
    margin-bottom: 30px;
    border-radius: 5px;
    border: 2px solid black;
    /* box-shadow: inset 0 0 10px black; */
}

@media (max-width: 1000px) {
    #intro-container {
        width: 80vw;
        padding: 10px;
    }
} 