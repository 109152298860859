
  #rabbit-holder {
    background-color: lightblue;
    background-image: url("./natural-paper.png");
    display: grid;
    place-items: center;
    height: 80vh;
    padding:0;
    margin:0;
  }
  @media (max-width: 500px) {
    #rabbit-holder {
      height: 60vh;
    }
  }
  
  #bicycle-container {
    position: relative;
    background-color: pink;
    background-image: url("./natural-paper.png");
    width: 400px;
    height: 400px;
    border-radius: 50%;
    box-shadow: 2px 2px 7px 2px gray;
  }

  @media (max-width: 500px) {
    #bicycle-container {
        width: 200px;
        height: 200px;
    }
} 
  
  .parts {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  
  .wheel {
    width: 30%;
    height: 30%;
    border-radius: 50%;
    background-color: #faf8ed;
    background-image: url("./natural-paper.png");
    border: 2px solid #33312c;
    animation: spin infinite 5s linear;
    box-shadow: 0.5px 0.5px 5px 1px rgba(0, 0, 0, 0.4);
  }
  
  #left-wheel {
    left:15%;
  }
  
  #right-wheel {
     left:58%;
  }
  
  .spoke {
    width: 103%;
    height: 2px;
    background-color: #33312c;
    transform: translate(-50%, 0%)
  }
  
  #spoke-1 {
    transform: translate(-50%, 0%)
  }
  
  #spoke-2 {
    transform: translate(-50%, 0%) rotate(90deg);
  }
  
  #spoke-3 {
    transform: translate(-50%, 0%) rotate(45deg);
  }
  
  #spoke-4 {
    transform: translate(-50%, 0%) rotate(135deg);
  }
  
  #spoke-5 {
    transform: translate(-50%, 0%)
  }
  
  #spoke-6 {
    transform: translate(-50%, 0%) rotate(90deg);
  }
  
  #spoke-7 {
    transform: translate(-50%, 0%) rotate(45deg);
  }
  
  #spoke-8 {
    transform: translate(-50%, 0%) rotate(135deg);
  }
  
  #bar {
    height: 3%;
    width: 50%;
    background-color: #33312c;
    transform: translate(-50%, 100%);
  }
  
  #seat-bar {
    height: 10%;
    width: 2%;
    background-color: #33312c;
    transform: translate(-400%, -50%)
  }
  
  #handlebar-bar {
    height: 20%;
    width: 2%;
    background-color: #33312c;
    transform: translate(400%, -70%)
  }
  
  #handlebar-bar-horizontal {
    height: 2%;
    width: 20%;
    border-radius: 20%;
    background-color: #33312c;
    transform: translate(-3%, -800%);
  }
  
  #seat {
    width: 10%;
    height: 4%;
    background-color: #faf8ed;
    background-image: url("./natural-paper.png");
    border: 2px solid #33312c;
    border-radius: 10px 45% 45% 10px;
    transform: translate(-110%, -165%);
  }
  
  #rabbit-body {
    height: 15%;
    width: 10%;
    border: 2px solid #33312c;
    border-radius: 45%;
    transform: translate(-120%, -150%);
  }
  
  #rabbit-head {
    height: 8%;
    width: 8%;
    border: 2px solid #33312c;
    border-radius: 45%;
    transform: translate(-138%, -338%);
  }
  
  .ear {
    height: 10%;
    width: 2%;
    border: 2px solid #33312c;
    border-radius: 50%;
  }
  
  #left-ear {
    transform: translate(-400%, -360%);
  }
  @media (max-width: 500px) {
    #left-ear {
        transform: translate(-350%, -360%);
    }
} 
  
  #right-ear {
    transform: translate(-241%, -360%);
  }
  
  .rabbit {
    background-color: lightblue;
    background-image: var(--paper);
  }
  
  .eye {
    background-color: #33312c;
    height: 1%;
    width: 1%;
    border-radius: 50%;
  }
  
  #left-eye {
    top: 22%;
    left: 40%;
  }
  
  #right-eye {
    top: 22%;
    left: 43%;
  }

  @media (max-width: 500px) {
    #left-eye {
        top: 20%;
        left: 39%;
    }
    #right-eye {
        top: 20%;
        left: 42%;
    }
} 
  
  @keyframes spin {
      from {
          transform:rotate(0deg);
      }
      to {
          transform:rotate(360deg);
      }
  }
  