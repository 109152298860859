.App {
  text-align: center;
}

a, a:hover, a:focus, a:active { text-decoration: none; color: inherit; }

h1, h2, h3, h4 {
  font-weight: normal;
  margin: 0;
  font-family: 'Philosopher', sans-serif;
}

h1 {
  font-size: clamp( 2rem, 5vw, 3.3rem);
}

p {
  font-size: 17px;
}



 