.silly-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0px;
    background-color: #1e8f71;
    box-shadow: inset 0px 0px 30px #b7c4c3;
    box-shadow: inset 0px 0px 30px #daf2b1;
} 

@media (max-width: 1000px) {
    .main-content-container {
        padding: 10px 0px;
    }
} 